import React from 'react';
import logo from './shlok_logo.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="circle">
              <img src={logo} className="App-logo" alt="logo"/>
          </div>
          <p className="main-text">
              Coming soon
          </p>
      </header>
    </div>
  );
}

export default App;
